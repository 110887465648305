<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'home' }" class="root">
                Dashboard
            </router-link>
            /
            <router-link :to="{name: 'admin-master' }" class="root">
                Admin Master
            </router-link>
            <span class="child">/ Accompanying Materials </span>
        </v-row>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">
                <span>Accompanying Materials</span>
            </v-card-title>
            <v-card-text>
                <div class="add-section">
                    <div style="text-align: center;">
                        <v-col>
                            <v-form ref="form">
                            <label class="add-text">Add New Accompanying Materials</label>
                            <v-text-field class="text" v-model="phyname" outlined dense :rules="[rules.required]" clearable required></v-text-field>
                            <v-btn color="success" @click="addAccompanyingMaterials">Add</v-btn>
                            </v-form>
                        </v-col>
                    </div>
                </div>

                <template>
                    <div class="text-center">
                        <v-dialog v-model="dialog" width="500">
                            <v-card>
                                <v-card-title class="grey lighten-2">
                                    Edit Accompanying Materials
                                </v-card-title>

                                <v-card-text style="padding-bottom: 0px;">
                                    <label class="add-text">Accompanying Materials</label>
                                    <v-text-field class="text" v-model="phyname" outlined dense :rules="[rules.required]" clearable required></v-text-field>
                                </v-card-text>

                                <!-- <v-divider></v-divider> -->

                                <div class="text-center">
                                    <v-spacer></v-spacer>
                                    <v-btn class="m-3" @click="dialog = false">
                                        Close
                                    </v-btn>
                                    <v-btn class="m-3" @click="saveEdit()">Update</v-btn>
                                </div>
                            </v-card>
                        </v-dialog>
                    </div>
                </template>

                <template v-if="Physical_details_list!=null">
                    <v-data-table :headers="headers" :items="Physical_details_list" :search="search" class="elevation-1 nowrap">
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table" single-line hide-details></v-text-field>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.edit="{ item }">
                            <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
                            <v-icon v-if="item" class="mr-2" @click="updatePhysicalDetails(item)">mdi mdi-pencil-box</v-icon>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <!-- <v-btn :loading="edit_btn_load" @click="editItem(item)" class="link" color="info"> <v-icon title="Activate/Deactivate the User Type">mdi-recycle</v-icon></v-btn> -->
                            <v-switch v-model="item.accompanyingMaterialsIsactive" @change="editItem(item)"></v-switch>
                        </template>
                        <template v-slot:item.delete="{ item }">
                            <v-icon v-if="item" class="mr-2" @click="deleteAccompanyingMaterials(item)"> mdi mdi-delete</v-icon>
                        </template> 
                    </v-data-table>
                </template>
            </v-card-text>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import axios from "axios";
    import "@/assets/css/color.css"; // css needs to be imported for each dashboard
    export default {
        data: () => ({
            overlay:false,
            snackbar_msg: "",
            snackbar: false,
            color: "",
            org: null,
            dialog: false,
            search: "",
            phyname:null,
            pysicaldetailsId:null,
            languageName:null,
            userType: null,
            edit_btn_load: false,
            editUserType: null,
            editUserTypeId: null,
            rules: {
                required: (value) => !!value || "Required.",
            },
            Physical_details_list: null,
            headers: [
                { text: "Sr no", value: "srno" },
                { text: "Name", value: "phyname" },
                { text: "Edit", value: "edit" },
                { text: "Toggle", value: "actions", sortable: false },
                 { text: "Delete", value: "delete" },
            ],
        }),
        watch: {},
        methods: {
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            editItem(item) {
                console.log(item);
                axios
                    .post("/admin/accompanyingMaterialsIsactive", item)
                    .then(() => {
                        // this.sec_mode_list = res.data.user_list;
                        this.showSnackbar("#4CAF50", " Accompanying Materials updated successfully...");
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.edit_btn_load = false;
                        axios;
                    });
            },

            updatePhysicalDetails(item) {
                this.dialog = true;
                this.phyname = item.phyname;
                console.log("hiiiii :"+this.phyname)
                this.pysicaldetailsId = item.pysicaldetailsId;
            },

            saveEdit() {
                alert(this.phyname );
                if(this.phyname){
                this.phyname = this.phyname.trim()
                }
                const data = {
                    phyname: this.phyname,
                    pysicaldetailsId: this.pysicaldetailsId,
                };
                if (this.phyname) {
                    axios
                        .post("/admin/editAccompanyingMaterials",data)
                        .then((res) => {
                            if (res.data.msg == "success") {
                            this.sec_mode_list = res.data.user_list;
                            this.showSnackbar("#4CAF50", "Accompanying Materials updated successfully...");
                            this.onload();
                            this.dialog = false;
                            } else {
                                this.showSnackbar("#b71c1c", res.data.msg);
                            }
                        })
                        .catch((error) => {
                            this.showSnackbar("#b71c1c", "Something went wrong!!!");
                            console.log(error);
                        })
                        .finally(() => {
                            this.edit_btn_load = false;
                            // this.editUserType = null;
                            axios;
                        });
                } else {
                    this.showSnackbar("#b71c1c", "Please enter Accompanying Materials");
                }
            },
             deleteAccompanyingMaterials(item){                  
               
                axios
                    .post("/admin/deleteAccompanyingMaterials", item)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.showSnackbar("#4CAF50", "Accompanying Materials Deleted Successfully  !!");
                            this.onload();
                        }else if(res.data.msg == "exp"){
                           this.showSnackbar("#b71c1c", "Can not be Deleted!!!");                          
                       }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        window.console.log(error);
                    });
            },

            onload() {
                this.overlay = true
                axios
                    .post("/admin/getAccompanyingMaterials")
                    .then((res) => {
                        this.org = res.data.org;
                        this.Physical_details_list = res.data.physicaldetails;
                        this.overlay = false
                        //this.showSnackbar("#4CAF50","Success")
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.overlay = false
                    });
            },

            addAccompanyingMaterials() {
                if(this.language){
                this.phyname = this.phyname.trim()
                }
                const data = {
                    phyicaldetails_name: this.phyname,
                };

                if (this.phyname) {
                    axios
                        .post("/admin/addAccompanyingMaterials", data)
                        .then((res) => {
                            this.org = res.data.org;
                            this.Physical_details_list = res.data.physicaldetails;
                            if (res.data.msg == "success") {
                                this.showSnackbar("#4CAF50", "Accompanying Materials added successfully...");
                                this.userType = null;
                                this.$refs.form.reset()
                            } else {
                                this.$refs.form.validate()
                                this.showSnackbar("#b71c1c", res.data.msg);
                            }
                        })
                        .catch((error) => {
                            this.showSnackbar("#b71c1c", "Something went wrong!!!");
                            console.log(error);
                        })
                        .finally(() => {});
                } else {
                    this.showSnackbar("#b71c1c", "Please enter Accompanying Materials");
                    this.$refs.form.validate()
                }
            },
        },
        mounted() {
            this.onload();
        },
    };
</script>
<style scoped>
    .heading {
        background-color: #3f51b5;
        padding: 0.3rem;
        color: white;
    }
    .v-icon {
        cursor: pointer;
    }
    .text >>> .v-input__slot {
        background: white !important;
        border-color: hsla(190, 99%, 30%, 1) !important;
    }
</style>
